<script lang="ts">
	import type { TeaserContent } from '../../../domain/TeaserContent.ts';
	import { onMount } from 'svelte';
	import {
		getMidnightRange,
		getMidnightRangeString,
		MidnightRange
	} from '../../../services/teaser/CountdownToMidnight.ts';
	import type { TeaserType } from '../../../domain/TeaserType.ts';
	import TrackingService from '../../../services/tracking/TrackingService.ts';
	import { onViewport } from '../../../services/tracking/IntersectionObserver';
	import { trackClick, trackSeen, trackView } from '../../../services/tracking/TeaserTracking.ts';


	let { teaserContent, teaserType, enableSignupSheetMigration, teaserSize }: {
		teaserContent: TeaserContent,
		teaserType: TeaserType,
		enableSignupSheetMigration: boolean,
		teaserSize: string,
	} = $props();

	const teaserContainerMargin = teaserContent.isProductList ? 'margin-in-product-list' : 'oc-mx-100';

	let headline = $state(teaserContent.heading);
	const headlinePrefix = headline;

	let teaser: Element;
	let trackingService: TrackingService;

	onMount(() => {
		trackingService = new TrackingService();

		watchForHeadlineUpdates();
		trackClick(trackingService, teaser, teaserContent, teaserType, teaserSize);
		trackView(trackingService, teaser, teaserContent, teaserType, teaserSize);
	});

	const watchForHeadlineUpdates = () => {
		const intervalId = window.setInterval(() => {
			const now = new Date();
			const end = new Date(teaserContent.countdown! * 1000);

			const time_range = end.getTime() - now.getTime();
			const midnightRange = getMidnightRange(time_range);

			switch (midnightRange) {
				case MidnightRange.BETWEEN_24_HOURS_AND_1_SECOND:
					headline = headlinePrefix + ' in ' + getMidnightRangeString(time_range);
					break;
				case MidnightRange.LESS_THAN_1_SECOND:
					window.clearInterval(intervalId);
					headline = 'Dein UP Plus ist abgelaufen';
					break;
				default:
					break;
			}
		}, 200);
	}

	const getSheetProps = () => {
		return JSON.stringify({
			id: `teaser-${teaserType}`,
			headline: teaserContent.sheetTitle,
			url: teaserContent.scarcity ? `${teaserContent.sheetUrl}&scarcity=${teaserContent.scarcity}` : teaserContent.sheetUrl,
			noContentPadding: true
		});
	}
</script>

{#if !enableSignupSheetMigration}
	<div class="oc-mt-100 oc-mb-150 js_openInPaliSheet {teaserContainerMargin}"
		id="teaser-l-container"
		data-testid="up-teaser-l-container"
		data-sheet-full-bleed-content="true"
		data-sheet-url={teaserContent.scarcity ? `${teaserContent.sheetUrl}&scarcity=${teaserContent.scarcity}` : teaserContent.sheetUrl}
		data-sheet-title={teaserContent.sheetTitle}
		data-sheet-id="teaser-{teaserType}"
		bind:this={teaser}
		use:onViewport
		on:enterViewport={() => trackSeen(trackingService, teaser, teaserContent, teaserType, teaserSize)}>
		<div class="pl_media-object_wrapper">
			<div class="pl_media-object100--red">
				<div class="pl_media-object--image pl_media-object--icon">
					<svg class="pl_icon" id="pl_icon_otto-up" role="img" style="color: red">
						<use
							data-testid="pl_icon_otto-up"
							href="/assets-static/icons/pl_icon_otto-up.svg#pl_icon_otto-up"
							xlink:href="/assets-static/icons/pl_icon_otto-up.svg#pl_icon_otto-up"></use>
					</svg>
				</div>

				<div class="pl_media-object--content">
					<div class="pl_media-object--headline">
						<div>{headline}</div>
					</div>
					<div class="pl_media-object--subline">{teaserContent.subheading}</div>
				</div>
			</div>
		</div>
	</div>
{:else }
	<div class="oc-mt-100 oc-mb-150 {teaserContainerMargin}"
		id="teaser-l-container"
		data-testid="up-teaser-l-container"
		data-oc-sheet-v1-create={getSheetProps()}
		bind:this={teaser}
		use:onViewport
		on:enterViewport={() => trackSeen(trackingService, teaser, teaserContent, teaserType, teaserSize)}>
		<div class="pl_media-object_wrapper">
			<div class="pl_media-object100--red">
				<div class="pl_media-object--image pl_media-object--icon">
					<svg class="pl_icon" id="pl_icon_otto-up" role="img" style="color: red">
						<use
							data-testid="pl_icon_otto-up"
							href="/assets-static/icons/pl_icon_otto-up.svg#pl_icon_otto-up"
							xlink:href="/assets-static/icons/pl_icon_otto-up.svg#pl_icon_otto-up"></use>
					</svg>
				</div>

				<div class="pl_media-object--content">
					<div class="pl_media-object--headline">
						<div>{headline}</div>
					</div>
					<div class="pl_media-object--subline">{teaserContent.subheading}</div>
				</div>
			</div>
		</div>
	</div>
{/if}
