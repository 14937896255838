<script lang="ts">
	import type { TeaserContent } from '../../../domain/TeaserContent.ts';
	import { logger } from '../../../logger.ts';
	import { onMount } from 'svelte';
	import TrackingService from '../../../services/tracking/TrackingService.ts';
	import { onViewport } from '../../../services/tracking/IntersectionObserver';
	import { TeaserType } from '../../../domain/TeaserType.ts';
	import { trackClick, trackSeen, trackView } from '../../../services/tracking/TeaserTracking.ts';

	let { teaserContent, teaserSize }: {
		teaserContent: TeaserContent,
		teaserSize: string
	} = $props();

	let originUrl = $state('');
	let teaserUri = $state('');

	const customerIdentityGeneratorUrl = '/up-teaserui/gateway/generateCustomerIdentityUrl';
	const teaserType = TeaserType.TO_LOGIN;

	let teaser: Element;
	let trackingService: TrackingService;

	onMount(() => {
		originUrl = window.location.href;
		trackingService = new TrackingService();

		fetch(`${customerIdentityGeneratorUrl}?originUrl=${originUrl}`, {
			credentials: 'include',
			method: 'GET',
			headers: { 'Content-Type': 'application/json' }
		}).then(response => {
			if (response.status === 200) {
				response.json().then(response => {
					teaserUri = response.encodedUrl;
					logger.debug(`Response ok - teaserUri: ${teaserUri}`);
				});
			} else {
				logger.error(`Status ${response.status} calling url generator`);
			}
		}).catch(error => {
			logger.error('Error generating url:' + error);
		});

		trackClick(trackingService, teaser, teaserContent, teaserType, teaserSize);
		trackView(trackingService, teaser, teaserContent, teaserType, teaserSize);
	});
</script>

<div class="oc-mt-100 oc-mb-150 oc-mx-100" id="up-teaser-l-container"
	data-testid="to-login-teaser"
	bind:this={teaser}
	use:onViewport
	on:enterViewport={() => trackSeen(trackingService, teaser, teaserContent, teaserType, teaserSize)}
>
	<a id="to-login-link" class="pl_media-object_wrapper to-login-link" href={teaserUri}>
		<div class="pl_media-object100--red">
			<div class="pl_media-object--image pl_media-object--icon">
				<svg class="pl_icon" id="pl_icon_otto-up" role="img">
					<use
						data-testid="pl_icon_otto-up"
						href="/assets-static/icons/pl_icon_otto-up.svg#pl_icon_otto-up"
						xlink:href="/assets-static/icons/pl_icon_otto-up.svg#pl_icon_otto-up"></use>
				</svg>
			</div>

			<div class="pl_media-object--content">
				<div class="pl_media-object--headline">
					<div>{teaserContent.heading}</div>
				</div>
				<div class="pl_media-object--subline">{teaserContent?.subheading}</div>
			</div>
		</div>
	</a>
</div>

<style lang="scss">
  .to-login-link {
    all: unset;
  }

</style>
